// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Repost from "../../blocks/Repost/src/Repost";
import StoreCredits from "../../blocks/StoreCredits/src/StoreCredits";
import Notifications from "../../blocks/Notifications/src/Notifications";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import FanWall from "../../blocks/FanWall/src/FanWall";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import RecurlyIntegration2 from "../../blocks/RecurlyIntegration2/src/RecurlyIntegration2";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import Reviews from "../../blocks/Reviews/src/Reviews";
import DonationPayments from "../../blocks/DonationPayments/src/DonationPayments";
import SavedCards from "../../blocks/SavedCards/src/SavedCards";
import CustomForm from "../../blocks/CustomForm/src/CustomForm";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmployeeLogin from "../../blocks/EmployeeLogin/src/EmployeeLogin";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import ContextTagging from "../../blocks/ContextTagging/src/ContextTagging";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import VisualAnalytics from "../../blocks/VisualAnalytics/src/VisualAnalytics";
import LiveChat from "../../blocks/LiveChat/src/LiveChat";
import VoiceMemos from "../../blocks/VoiceMemos/src/VoiceMemos";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import FullTeamVisibility from "../../blocks/FullTeamVisibility/src/FullTeamVisibility";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import Favourites from "../../blocks/Favourites/src/Favourites";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import FriendList from "../../blocks/FriendList/src/FriendList";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import Calendar from "../../blocks/Calendar/src/Calendar";
import LiveFeedScheduling from "../../blocks/LiveFeedScheduling/src/LiveFeedScheduling";
import LocationbasedAlerts from "../../blocks/LocationbasedAlerts/src/LocationbasedAlerts";
import BroadcastMessage from "../../blocks/BroadcastMessage/src/BroadcastMessage";
import AdvertisingApplicationApi2 from "../../blocks/AdvertisingApplicationApi2/src/AdvertisingApplicationApi2";
import DataImportexportcsv2 from "../../blocks/DataImportexportcsv2/src/DataImportexportcsv2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Gallery from "../../blocks/Gallery/src/Gallery";
import HashtagGenerator from "../../blocks/HashtagGenerator/src/HashtagGenerator";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import RecommendationEngine from "../../blocks/RecommendationEngine/src/RecommendationEngine";
import Analytics from "../../blocks/Analytics/src/Analytics";
import PostTimeLine2 from "../../blocks/PostTimeLine2/src/PostTimeLine2";
import Subscriptions from "../../blocks/Subscriptions/src/Subscriptions";
import KarmaPoints from "../../blocks/KarmaPoints/src/KarmaPoints";
import Chat from "../../blocks/Chat/src/Chat";
import AdvancedSearch2 from "../../blocks/AdvancedSearch2/src/AdvancedSearch2";
import AddFriends from "../../blocks/AddFriends/src/AddFriends";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import TwilioIntegration2 from "../../blocks/TwilioIntegration2/src/TwilioIntegration2";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ConversationThreading from "../../blocks/ConversationThreading/src/ConversationThreading";
import AudioLibrary from "../../blocks/AudioLibrary/src/AudioLibrary";
import Gamification from "../../blocks/Gamification/src/Gamification";
import AiAnalyticsApi2 from "../../blocks/AiAnalyticsApi2/src/AiAnalyticsApi2";
import Events from "../../blocks/Events/src/Events";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import Mentionstagging from "../../blocks/Mentionstagging/src/Mentionstagging";
import CfStripeGatewayIntegration3 from "../../blocks/CfStripeGatewayIntegration3/src/CfStripeGatewayIntegration3";
import Groups from "../../blocks/Groups/src/Groups";
import PushNotifications2 from "../../blocks/PushNotifications2/src/PushNotifications2";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import Payments from "../../blocks/Payments/src/Payments";
import Trending from "../../blocks/Trending/src/Trending";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ActivityLog from "../../blocks/ActivityLog/src/ActivityLog";
import Sms2 from "../../blocks/Sms2/src/Sms2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import Followers from "../../blocks/Followers/src/Followers";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import Download from "../../blocks/Download/src/Download";
import Hashtags from "../../blocks/Hashtags/src/Hashtags";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Chatbot from "../../blocks/Chatbot/src/Chatbot";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import AudiovideoMessenger from "../../blocks/AudiovideoMessenger/src/AudiovideoMessenger";
import Comments from "../../blocks/Comments/src/Comments";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import Location from "../../blocks/Location/src/Location";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ReviewPrompt from "../../blocks/ReviewPrompt/src/ReviewPrompt";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import AutomaticRenewals from "../../blocks/AutomaticRenewals/src/AutomaticRenewals";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import CustomisableUserSubscriptions from "../../blocks/CustomisableUserSubscriptions/src/CustomisableUserSubscriptions";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings";
import PhotoLibrary from "../../blocks/PhotoLibrary/src/PhotoLibrary";



const routeMap = {
Repost:{
 component:Repost,
path:"/Repost"},
StoreCredits:{
 component:StoreCredits,
path:"/StoreCredits"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
FanWall:{
 component:FanWall,
path:"/FanWall"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
RecurlyIntegration2:{
 component:RecurlyIntegration2,
path:"/RecurlyIntegration2"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
DonationPayments:{
 component:DonationPayments,
path:"/DonationPayments"},
SavedCards:{
 component:SavedCards,
path:"/SavedCards"},
CustomForm:{
 component:CustomForm,
path:"/CustomForm"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmployeeLogin:{
 component:EmployeeLogin,
path:"/EmployeeLogin"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
ContextTagging:{
 component:ContextTagging,
path:"/ContextTagging"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
LiveChat:{
 component:LiveChat,
path:"/LiveChat"},
VoiceMemos:{
 component:VoiceMemos,
path:"/VoiceMemos"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming"},
FullTeamVisibility:{
 component:FullTeamVisibility,
path:"/FullTeamVisibility"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
FriendList:{
 component:FriendList,
path:"/FriendList"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
LiveFeedScheduling:{
 component:LiveFeedScheduling,
path:"/LiveFeedScheduling"},
LocationbasedAlerts:{
 component:LocationbasedAlerts,
path:"/LocationbasedAlerts"},
BroadcastMessage:{
 component:BroadcastMessage,
path:"/BroadcastMessage"},
AdvertisingApplicationApi2:{
 component:AdvertisingApplicationApi2,
path:"/AdvertisingApplicationApi2"},
DataImportexportcsv2:{
 component:DataImportexportcsv2,
path:"/DataImportexportcsv2"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
HashtagGenerator:{
 component:HashtagGenerator,
path:"/HashtagGenerator"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PostTimeLine2:{
 component:PostTimeLine2,
path:"/PostTimeLine2"},
Subscriptions:{
 component:Subscriptions,
path:"/Subscriptions"},
KarmaPoints:{
 component:KarmaPoints,
path:"/KarmaPoints"},
Chat:{
 component:Chat,
path:"/Chat"},
AdvancedSearch2:{
 component:AdvancedSearch2,
path:"/AdvancedSearch2"},
AddFriends:{
 component:AddFriends,
path:"/AddFriends"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
TwilioIntegration2:{
 component:TwilioIntegration2,
path:"/TwilioIntegration2"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ConversationThreading:{
 component:ConversationThreading,
path:"/ConversationThreading"},
AudioLibrary:{
 component:AudioLibrary,
path:"/AudioLibrary"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
AiAnalyticsApi2:{
 component:AiAnalyticsApi2,
path:"/AiAnalyticsApi2"},
Events:{
 component:Events,
path:"/Events"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Mentionstagging:{
 component:Mentionstagging,
path:"/Mentionstagging"},
CfStripeGatewayIntegration3:{
 component:CfStripeGatewayIntegration3,
path:"/CfStripeGatewayIntegration3"},
Groups:{
 component:Groups,
path:"/Groups"},
PushNotifications2:{
 component:PushNotifications2,
path:"/PushNotifications2"},
UserProfileAdvance:{
 component:UserProfileAdvance,
path:"/UserProfileAdvance"},
Payments:{
 component:Payments,
path:"/Payments"},
Trending:{
 component:Trending,
path:"/Trending"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ActivityLog:{
 component:ActivityLog,
path:"/ActivityLog"},
Sms2:{
 component:Sms2,
path:"/Sms2"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
Followers:{
 component:Followers,
path:"/Followers"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
Download:{
 component:Download,
path:"/Download"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Chatbot:{
 component:Chatbot,
path:"/Chatbot"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
AudiovideoMessenger:{
 component:AudiovideoMessenger,
path:"/AudiovideoMessenger"},
Comments:{
 component:Comments,
path:"/Comments"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Location:{
 component:Location,
path:"/Location"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ReviewPrompt:{
 component:ReviewPrompt,
path:"/ReviewPrompt"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
CustomisableUserSubscriptions:{
 component:CustomisableUserSubscriptions,
path:"/CustomisableUserSubscriptions"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;